import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'

import ExamResults from '../ExamResults'

import { HOME } from '../../routes'
import { store } from '../../stores/app'
import { initUserSession } from '../../utils/session'

const PatientDetail = () => {
	const { state, dispatch } = useContext(store)
	// @ts-ignore
	initUserSession(state, dispatch)

	useEffect(() => {
		if (!state.patient.cpf) navigate(HOME)
	}, [])

	return (
		state.session.accessToken && (
			<>
				<ExamResults {...state.patient}/>
			</>
		)
	)
}

export default PatientDetail
