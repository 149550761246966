import React, { useCallback, useEffect } from 'react'
import { ButtonFilter, Col, Grid, InputElement, InputLabel, Search, Span } from './styles'

interface IProps {
	idSufix?: string
	state: { searchText: string; endDate: string; startDate: string }
	onChangeFilter: (term: string, name: string) => void
	onKeyPress: () => void
	placeholderSearch: string
	labelDate: string
	onFilter: () => void
	onlyDate?: boolean
}
const SearchTextDate = ({
	idSufix = '',
	state,
	onChangeFilter,
	onKeyPress,
	placeholderSearch,
	labelDate,
	onFilter,
	onlyDate
}: IProps) => {
	useEffect(() => {
		if (!state.startDate && !state.endDate) onFilter()
	}, [state.startDate, state.endDate])

	const handleChangeText = useCallback((e: any) => onChangeFilter(e.target.value, 'searchText'), [])
	const handleChangeStartDate = useCallback((e: any) => onChangeFilter(e.target.value, 'startDate'), [])
	const handleChangeEndDate = useCallback((e: any) => onChangeFilter(e.target.value, 'endDate'), [])

	return (
		<>
			{!onlyDate && (
				<Col size='6' hd='6' lapTop='7' tablet='12' padding='10px 0 0 30px' psm='10px'>
					<Search
						value={state.searchText}
						onChange={handleChangeText}
						onKeyPress={onKeyPress}
						onSearch={onKeyPress}
						label={placeholderSearch}
						fontSize='16px/24px'
						id={`input-search-text-${idSufix}`}
						idButton='button-search-icon'
					/>
				</Col>
			)}
			<Col size='12' padding={onlyDate ? '10px 0 0 30px' : '0 0 0 30px'} psm='10px'>
				<Grid rowGap='0'>
					<Col size='12'>
						<Span fontSize='16px/24px' bold>
							{labelDate}
						</Span>
					</Col>
					<Col tablet='4' flex flexDirection='column'>
						<InputLabel fontSize='14px/24px'>De</InputLabel>
						<InputElement
							p='2px 10px'
							withBorder
							id={`filter-exams-date-start-${idSufix}`}
							placeholder='Ex.: 02/02/2020'
							value={state.startDate}
							onChange={handleChangeStartDate}
						/>
					</Col>
					<Col tablet='4' flex flexDirection='column'>
						<InputLabel fontSize='14px/24px'>Até</InputLabel>
						<InputElement
							p='2px 10px'
							withBorder
							id={`filter-exams-date-end-${idSufix}`}
							placeholder='Ex.: 10/04/2020'
							value={state.endDate}
							onChange={handleChangeEndDate}
						/>
					</Col>
					<Col tablet='4' flex alingItems='flex-end'>
						<ButtonFilter id={`button-filter-${idSufix}`} onClick={onFilter}>
							Filtrar
						</ButtonFilter>
					</Col>
				</Grid>
			</Col>
		</>
	)
}
export default SearchTextDate
