import api from './api'

const callGetExams = async (cpf: string) => {
	const url = `/patients/${cpf}/exams`
	try {
		const response = await api.get(url, {})
		return response
	} catch (err) {
		return err.response
	}
}

export default callGetExams
