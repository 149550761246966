import api from './api'

const callGetProntmed = async (cpf: string) => {
	const url = `/patients/${cpf}/prontmed`
	try {
		const response = await api.get(url, {})
		return response
	} catch (err) {
		return err.response
	}
}

export default callGetProntmed
