import ArrowIcon from '@cuidardigital/commons/assets/icons/arrowBack'
import callGetExams from '@cuidardigital/commons/services/get-exams'
import IExam from '@cuidardigital/commons/types/IExam'
import { navigate } from 'gatsby'
import __filter__ from 'lodash/filter'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import MedicalRecord from '@cuidardigital/commons/components/MedicalRecord'
import {
	getPdfsConsultation,
	getPrescriptions,
	postCreateClosedConsultation,
	postCreateClosedConsultationBradesco,
	postSendNotification,
	putProntmedId
} from '@cuidardigital/commons/services/appointment'
import {
	getCertificatesValid,
	getPushValid,
	getQrCodeValid,
	postCheckPushValid,
	postSignPdfBradesco
} from '@cuidardigital/commons/services/certification.valid.services'
import IModalMessage from '@cuidardigital/commons/types/IModalMessage'
import { isoToDateHour, maskDate, orderDate } from '@cuidardigital/commons/utils/date'
import { downloadPdf } from '@cuidardigital/commons/utils/download'
import { DOC_TYPE } from '@cuidardigital/commons/utils/enumPdfs'
import { mobilecheck } from '@cuidardigital/commons/utils/isMobile'
import JSPanel from '@cuidardigital/commons/utils/jsPanel'
import { getCertSocketValid, getSO } from '@cuidardigital/commons/utils/validListcertificates'
import TemplatePage from '../../templates/Page'
import { store, TYPES } from '../../stores/app'
import { HOME } from '../../routes'
import useCustomState from '../../hooks/useState'
import { CollapseExam } from '../Collapse'
import CollapsePrescription from '../Collapse/PrescriptionCollapse'
import MobileMenuConsulting from '../Menu/Mobile/Consulting'
import MessageModal from '../Modal'
import DigitalCertificateModal from '../Modal/DigitalCertificate'
import ModalDigitalSign, { IModalDigitalSign } from '../Modal/ModalDigitalSign'
import SearchTextDate from './SearchTextDate'
import Sidebar, { AREA_PATHS } from './Sidebar'
import {
	BackToHome,
	Col,
	ComponentWrapper,
	ContainerResults,
	Grid,
	Loader,
	MedicalRecordContainer,
	SpacedCol,
	Span
} from './styles'

interface IGroupByExams {
	[key: string]: IExam[]
}

let cpfAssinatura = ''
const isMobile = mobilecheck()

const ExamResults = ({
	consultationId,
	cpf,
	name,
	email,
	phone,
	birthDay,
	doctorEmail,
	link,
	newConsultation,
	lastConsulting
}: {
	consultationId: string
	cpf: string
	name: string
	email: string
	phone: string
	birthDay: string
	doctorEmail: string
	link: string
	newConsultation: boolean
	lastConsulting: any
}) => {
	// @ts-ignore
	const { state, dispatch } = useContext(store)

	const [customState, setState] = useCustomState({
		searchText: '',
		startDate: '',
		endDate: '',
		examsList: [],
		searchedByDate: false,
		filteredExamsList: null,
		searchTextPrescription: '',
		startDatePrescription: '',
		endDatePrescription: ''
	})

	const [sessionOpened, setSessionOpened] = useState(AREA_PATHS.PRONTMED)
	const [haveExams, setHaveExams] = useState(false)
	const [currentActive, setCurrentActive] = useState('')
	const [examList, setExamList] = useState<IExam[]>([])
	const [filteredExamList, setFilteredExamList] = useState<IExam[]>([])
	const [loading, setLoading] = useState(false)
	const [showMessage, setShowMessage] = useState(false)
	const [messageToShow, setMessageToShow] = useState<any>()

	const [prescriptionActive, setPrescriptionActive] = useState('')
	const [prescriptionList, setPrescriptionList] = useState<any[]>([])
	const [filteredPrescriptions, setFilteredPrescriptions] = useState<any[]>([])
	const [certs, setCerts] = useState<any[]>([])
	const [openModalDigitalSign, setOpenModalDigitalSign] = useState(false)
	const [paramsModalDigitalSign, setParamsModalDigitalSign] = useState<IModalDigitalSign>({})
	const [isDigitalCertificate, setIsDigitalCertificate] = useState(false)
	const [digitalCertificateAcceptance, setDigitalCertificateAcceptance] = useState(null)
	// states para usar na assinatura digital
	const [dataToSign, setDataToSign] = useState({
		codeAuth: '',
		accessTokens: { ...(state.session.accessTokens || {}) },
		cert: '',
		docs: '',
		stepLabel: '',
		certDefault: false,
		certifier: '',
		prontmedId: '',
		cb: null
	})

	useEffect(() => {
		setFilteredExamList(examList)
	}, [examList])

	useEffect(() => {
		getExams()
	}, [cpf, link, newConsultation])

	useEffect(() => {
		if (sessionOpened === AREA_PATHS.PRESCRIPTIONS) getPatientPrescriptions()
	}, [sessionOpened])

	useEffect(() => {
		if (dataToSign.stepLabel) {
			switch (dataToSign.stepLabel) {
				case 'SELECT_CERT':
					handleSignSelectCert()
					break
				case 'SELECT_DOCS':
					handleSignSelectDocs()
					break
				case 'PUSH':
					handleSignPush()
					break
				case 'PIN_SOLUTI':
					handleSignPinSoluti()
					break
				case 'SIGNATURE':
					signPdfs()
					break
				case 'CHECK_READY' || 'TRY_AGAIN_CHECK_READY':
					checkPushReady()
					break
				case 'SEND_EMAIL':
					handleSignSelectDocs({
						onAction: dataToSign.cb
					})
					break
				case 'DOWNLOAD':
					handleSignSelectDocs({
						onAction: dataToSign.cb
					})
					break
			}
		}
	}, [dataToSign.stepLabel])

	const getExams = () => {
		try {
			setLoading(true)
			// @ts-ignore
			callGetExams(cpf)
				.then(response => {
					let newList = []
					if (response.status === 200) {
						const fullList = response.data.labExams.concat(response.data.imageExams)

						newList = fullList
							.sort((a: any, b: any) => {
								const dateA = new Date(a.date)
								const dateB = new Date(b.date)
								return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
							})
							.map((exam: any) => {
								const newDate = `${exam.date.substring(8, 10)}/${exam.date.substring(
									5,
									7
								)}/${exam.date.substring(0, 4)}`

								return {
									id: exam.id,
									date: newDate,
									name: exam.name
								}
							})

						setHaveExams(newList.length > 0)
						setExamList(newList)
					} else {
						setSessionOpened(AREA_PATHS.PRONTMED)
					}
				})
				.catch(e => {
					if (process.env.NODE_ENV === 'development')
						console.log(
							{
								message:
									'Não foi possível realizar sua solicitação. Tente novamente e caso o erro persista, entre em contato com o suporte'
							},
							e
						)
				})
				.finally(() => {
					setLoading(false)
				})
			if (newConsultation && link) handleOpenVideoCall()
		} catch (e) {
			setLoading(false)
			handleShowModal({
				title: 'Pedimos desculpa',
				message: e,
				buttonText: 'Ok, entendi'
			})
		}
	}

	const handleShowModal = (messageObj?: IModalMessage) => {
		if (messageObj && (messageObj.message || messageObj.messageHTML)) {
			setMessageToShow(messageObj)
			setShowMessage(true)
		} else setShowMessage(false)
	}

	const handleFilteredExams = () => {
		const { startDate, endDate, searchText } = customState

		const search = !searchText ? '' : searchText

		if ((startDate && endDate) || (searchText && searchText.length > 0)) {
			const filtered = filter({ items: examList, startDate, endDate, search })
			setFilteredExamList(filtered)
		} else {
			setFilteredExamList(examList)
		}
	}

	const handleFilterPrescriptions = () => {
		const {
			startDatePrescription: startDate,
			endDatePrescription: endDate,
			searchTextPrescription: search
		} = customState

		if ((startDate && endDate) || (search && search.length > 0)) {
			const filtered = filter({
				items: prescriptionList,
				startDate,
				endDate,
				search,
				onlyDate: true,
				dateField: 'dateFormatted'
			})
			setFilteredPrescriptions(filtered)
		} else {
			setFilteredPrescriptions(prescriptionList)
		}
	}

	const filter = ({ items, startDate, endDate, search, onlyDate = false, dateField = 'date' }) => {
		const filtered = __filter__(items, (item: any) => {
			const dateItem = moment(item[dateField], 'DD/MM/YYYY')
			const nameLower = onlyDate ? '' : item.name.toLowerCase()
			const dateStart = moment(startDate, 'DD/MM/YYYY')
			const dateEnd = moment(endDate, 'DD/MM/YYYY')

			const validDates = dateStart.isValid() && dateEnd.isValid()
			const equalDates = dateItem.isSame(dateStart) || dateItem.isSame(dateEnd)
			const dateMatchFilter = validDates
				? dateItem.isBetween(dateStart, dateEnd) || equalDates
				: true
			return dateMatchFilter && nameLower.includes(search.toLowerCase())
		})
		return filtered
	}

	const examsByDay: IGroupByExams = useMemo(
		() =>
			filteredExamList.reduce((group: IGroupByExams, item: IExam) => {
				group[item.date] = (group[item.date] || []).concat([item])
				return group
			}, {}),
		[filteredExamList]
	)

	const handleClickCollapse = useCallback(
		(id: string) => () => {
			setCurrentActive(id === currentActive ? '' : id)
		},
		[currentActive]
	)

	const handleCollapsePrescription = useCallback(
		(id: any) => () => {
			setPrescriptionActive(id === prescriptionActive ? '' : id)
		},
		[prescriptionActive]
	)

	const handleSendEmail = useCallback(
		(idConsultationProntmed: string, idConsultation: string, signed: boolean) => ({
			docs
		}: any) => {
			const types = Object.keys(docs).filter(key => docs[key])

			const then = res => {
				setLoading(false)
				setOpenModalDigitalSign(false)
				if (res && res.status >= 200 && res.status <= 207) {
					setFilteredPrescriptions(prev =>
						prev.map(item => {
							if (item.id === idConsultation) return { ...item, sentEmail: true }
							return item
						})
					)
				} else
					handleShowModal({
						title: 'Pedimos desculpas',
						message: `Não conseguimos enviar o email/n${res?.data?.message}`,
						buttonText: 'Tentar de novo',
						onClose: () => request({ docs: types }),
						secondButton: {
							onClick: () => {
								handleShowModal()
								handleSignClose()
							},
							label: 'Agora não'
						}
					})
			}
			const request = ({ docs }) => {
				if (!signed && docs.includes('prescriptionControlled'))
					handleShowModal({
						title: 'Confirmação',
						message:
							'Você está prestes a enviar para o paciente uma receita de controle especial sem autenticação de assinatura. /nDeseja continuar?',
						buttonText: 'Enviar',
						onClose: () => {
							handleShowModal()
							setLoading(true)
							postSendNotification(idConsultationProntmed, docs).then(then)
						},
						secondButton: {
							onClick: () => {
								handleShowModal()
								handleSignClose()
							},
							label: 'Agora não'
						}
					})
				else {
					setLoading(true)
					postSendNotification(idConsultationProntmed, docs).then(then)
				}
			}

			if (types.length === 1) request({ docs: types })
			else {
				setDataToSign(prev => ({
					...prev,
					docs,
					stepLabel: 'SEND_EMAIL',
					cb: request
				}))

				setOpenModalDigitalSign(true)
			}
		},
		[state.session]
	)

	const handleDownload = useCallback(
		(idConsultationProntmed: string, idConsultation: string) => ({ docs }: any) => {
			const prescription = prescriptionList.find(
				item => item.medicalEncounterId === idConsultationProntmed
			)

			const types = Object.keys(docs).filter(key => docs[key])
			const then = res => {
				setLoading(false)
				setOpenModalDigitalSign(false)
				if (res && res.status >= 200 && res.status <= 207) {
					const { patient, date } = prescription
					const fileName = `${patient.name} - $$$ - ${isoToDateHour(date)}`
					res.data.forEach(item =>
						downloadPdf(item.base64, fileName.replace('$$$', DOC_TYPE[item.type]))
					)

					setFilteredPrescriptions(prev =>
						prev.map(item => {
							if (item.id === idConsultation) return { ...item, downloaded: true }
							return item
						})
					)
					// setTimeout(() => setDownloaded(() => ''), 10000)
				} else
					handleShowModal({
						title: 'Pedimos desculpas',
						message: `Não conseguimos realizar o download/n${res?.data?.message}`,
						buttonText: 'Tentar de novo',
						onClose: () => request({ docs: types }),
						secondButton: {
							onClick: () => {
								handleShowModal()
								handleSignClose()
							},
							label: 'Agora não'
						}
					})
			}

			const request = ({ docs }) => {
				setLoading(true)
				getPdfsConsultation(idConsultationProntmed, docs).then(then)
			}

			if (types.length === 1) request({ docs: types })
			else {
				setDataToSign(prev => ({
					...prev,
					docs,
					stepLabel: 'DOWNLOAD',
					cb: request
				}))

				setOpenModalDigitalSign(true)
			}
		},
		[state.session, prescriptionList]
	)

	const handleSendSMS = useCallback(
		(idConsultationProntmed: string, idConsultation: string) => ({ docs }: any) => {
			const then = res => {
				setLoading(false)
				setOpenModalDigitalSign(false)
				if (res && res.status >= 200 && res.status <= 207) {
					setFilteredPrescriptions(prev =>
						prev.map(item => {
							if (item.id === idConsultation) return { ...item, sentSMS: true }
							return item
						})
					)
				} else
					handleShowModal({
						title: 'Pedimos desculpas',
						message: `Não conseguimos enviar o SMS/n${res?.data?.message}`,
						buttonText: 'Tentar de novo',
						onClose: () => request(),
						secondButton: {
							onClick: () => {
								handleShowModal()
								handleSignClose()
							},
							label: 'Agora não'
						}
					})
			}
			const request = () => {
				setLoading(true)
				postSendNotification(idConsultationProntmed, [], 'sms').then(then)
			}
			request()
		},
		[state.session]
	)

	const handleSign = (id: string) => ({ docs }: any) => {
		setDataToSign({ ...dataToSign, docs, stepLabel: 'SELECT_CERT', prontmedId: id })
	}

	const backToHome = () => {
		navigate(HOME)
	}

	const handleFilter = (sufix: string) => (value: string, name: string) =>
		setState({
			[`${name}${sufix}`]: name !== 'startDate' && name !== 'endDate' ? value : maskDate(value)
		})

	const handleOpenVideoCall = () => {
		const panels = document.getElementsByClassName('jsPanel')
		if (panels && !panels.length) JSPanel(link, 'Consulta')
		setSessionOpened(AREA_PATHS.PRONTMED)
	}

	const handleChangeView = (areaView: string) => () => {
		if (areaView === AREA_PATHS.OPEN_CALL) handleOpenVideoCall()
		else setSessionOpened(areaView)
	}

	const handleSignClose = () => {
		setOpenModalDigitalSign(false)
		setDataToSign({ ...dataToSign, stepLabel: '' })
	}

	const handleSignStart = () => {
		setOpenModalDigitalSign(true)
		setParamsModalDigitalSign({
			onAccept: () => {
				setSessionOpened(AREA_PATHS.PRESCRIPTIONS)
				handleSignClose()
			},
			onDecline: handleSignClose,
			viewType: 'START'
		})
	}

	const handleSignSelectCert = async () => {
		const { docs, accessTokens, certifier } = dataToSign
		let normalizeCerts = [...certs]

		if (state.session.default) {
			normalizeCerts = [state.session.cert]
			setParamsModalDigitalSign({
				docs,
				onAccept: ({ docs }) =>
					setDataToSign(prev => ({
						...prev,
						docs,
						cert: state.session.cert,
						stepLabel: accessTokens[certifier]
							? 'SIGNATURE'
							: certifier === 'valid'
							? 'PUSH'
							: 'PIN_SOLUTI'
					})),
				onDecline: handleSignClose,
				viewType: 'SELECT_DOCS'
			})
		} else {
			setLoading(true)
			const promises: any = [[], []]
			// if (!certs.length) {
			// if (getSO() === 'windows') {
			// 	promises[0] = new Promise(resolve =>
			// 		getCertSocketValid(
			// 			res => {
			// 				try {
			// 					let clientCerts = []
			// 					if (res.content.certificates) {
			// 						clientCerts = res.content.certificates.map(
			// 							({ issuerCN, validity, thumbprint }) => ({
			// 								label: `${issuerCN} - ${validity}`,
			// 								value: thumbprint
			// 							})
			// 						)
			// 					}
			// 					resolve(clientCerts)
			// 				} catch {
			// 					resolve([])
			// 				}
			// 			},
			// 			e => console.log('error', e)
			// 		)
			// 	)
			// }
			promises[1] = getCertificatesValid(cpfAssinatura || state.session.cpf)

			const [fromSocket, fromCloud]: any = await Promise.all(promises)
			const normalizeFromCloud = Object.keys(fromCloud?.data || {}).reduce((value, item) => {
				const slots =
					(fromCloud?.data[item] || {}).slots?.map(c => ({
						value: c.slot_alias,
						label: `${item.toUpperCase()} - ${c.label}`,
						certifier: item
					})) || []
				return [...value, ...slots]
			}, [])
			normalizeCerts = [...fromSocket, ...normalizeFromCloud]
			// }

			setLoading(false)

			setParamsModalDigitalSign({
				onAccept: args => {
					const { cert, certDefault, certifier } = args
					const payload = { cert }
					// se marcou o check para usar o cert na sessão salva na store
					if (certDefault) {
						payload.default = true
						payload.certifier = certifier
					}
					dispatch({ type: TYPES.SET_USER_SESSION, payload })
					setDataToSign(prev => ({
						...prev,
						cert,
						certDefault,
						certifier,
						stepLabel: 'SELECT_DOCS'
					}))
				},
				isWindows: getSO() === 'windows',
				onDecline: handleSignClose,
				viewType: 'SELECT_CERT',
				certList: normalizeCerts
			})
		}
		if (normalizeCerts.length) {
			setCerts(normalizeCerts)
			setOpenModalDigitalSign(true)
		} else {
			setDataToSign({ ...dataToSign, stepLabel: '' })
			handleShowModal({
				buttonText: 'Ver oferta',
				messageHTML: `Não encontramos um Certificado tipo nuvem para o seu CPF na base das certificadoras parceiras.
					<br/>Temos uma oferta por tempo limitado totalmente grátis.`,
				onClose: () => {
					setIsDigitalCertificate(true)
					handleShowModal()
				},
				title: 'Sem certificados',
				secondButton: {
					label: 'Agora não',
					onClick: handleShowModal
				}
			})
		}
	}

	const handleSignSelectDocs = ({ onAction } = {}) => {
		const { certifier, accessTokens, docs } = dataToSign
		let stepLabel = ''

		if (docs) {
			const keys = Object.keys(docs)
			if (keys.length === 1) {
				if (onAction) onAction({ docs: keys })
				else {
					stepLabel = accessTokens[certifier]
						? 'SIGNATURE'
						: certifier === 'valid'
						? 'PUSH'
						: 'PIN_SOLUTI'
				}
				setDataToSign(prev => ({
					...prev,
					docs: keys,
					stepLabel
				}))
				return <></>
			}
			setParamsModalDigitalSign({
				docs: dataToSign.docs,
				onAccept: ({ docs }) => {
					if (onAction) onAction({ docs })
					else {
						stepLabel = accessTokens[certifier]
							? 'SIGNATURE'
							: certifier === 'valid'
							? 'PUSH'
							: 'PIN_SOLUTI'
					}
					setDataToSign(prev => ({
						...prev,
						docs,
						stepLabel
					}))
				},
				onDecline: handleSignClose,
				viewType: 'SELECT_DOCS'
			})
		}
	}

	const handleSignPush = ({ expired } = {}) => {
		const { cert, accessTokens, docs, certifier } = dataToSign
		setLoading(true)

		if (accessTokens[certifier] && !expired) {
			setDataToSign({ ...dataToSign, stepLabel: 'SIGNATURE' })
			setOpenModalDigitalSign(false)
		} else if (cert && (!cert?.label?.includes('Valid') || !cert?.label?.includes('Soluti'))) {
			// por enquanto, se não for valid é um certificado não cloud e só tenta assinar
			setDataToSign({ ...dataToSign, stepLabel: 'SIGNATURE' })
			setOpenModalDigitalSign(false)
		} else {
			getPush()
			setOpenModalDigitalSign(true)
			setParamsModalDigitalSign({
				onAccept: () => setDataToSign(prev => ({ ...prev, stepLabel: 'CHECK_READY' })),
				onDecline: getPush,
				onQrCode: () => handleSignQrCode({ docs, cert }),
				viewType: 'PUSH',
				onExit: handleSignClose
			})
		}
	}

	const handleSignQrCode = ({ docs, cert }) => {
		getQrCodeValid(cpfAssinatura || state.session.cpf)
			.then(resQrcode => {
				// window.open(resQrcode.data, '_blank')
				JSPanel(resQrcode.data, 'Vidaas', {
					contentSize: {
						height: '90vh',
						width: '400px'
					}
				})
			})
			.catch(e => {
				console.log('resQrcode error', e)
			})
		setParamsModalDigitalSign({
			onAccept: handleSignFinish,
			onDecline: handleSignClose,
			onPush: () => handleSignPush({ docs, cert }),
			viewType: 'QR_CODE'
		})
	}
	const handleSignFinish = () => {
		const accept = () => {
			getPatientPrescriptions()
			handleSignClose()
		}
		setOpenModalDigitalSign(true)
		setParamsModalDigitalSign({
			onAccept: accept,
			onDecline: () => {
				handleSignClose()
				navigate(HOME)
			},
			onExit: accept,
			viewType: 'FINISH'
		})
	}

	const handleSignPinSoluti = () => {
		setParamsModalDigitalSign({
			onAccept: (pin: string) =>
				setDataToSign(prev => ({ ...prev, codeAuth: pin, stepLabel: 'CHECK_READY' })),
			onDecline: handleSignClose,
			viewType: 'PIN_SOLUTI'
		})
	}

	const signPdfs = async () => {
		const { accessTokens, docs, cert, prontmedId, certifier } = dataToSign
		setLoading(true)
		postSignPdfBradesco({
			consultationId: prontmedId,
			token: accessTokens[certifier],
			docs,
			certifier: certifier.toUpperCase()
		})
			.then(resp => {
				setLoading(false)
				if (resp) {
					if (resp.status >= 200 && resp.status <= 207) handleSignFinish()
					else if (resp.status === 412) {
						dispatch({ type: TYPES.SET_USER_SESSION, payload: { accessTokens: {} } })
						handleShowModal({
							title: 'Sessão do certificado expirada',
							message:
								'É necessário autorizar novamente via notificação para renovar a sessão do certificado',
							buttonText: 'Enviar notificação',
							onClose: () => handleSignPush({ expired: true }),
							secondButton: {
								onClick: () => {
									handleShowModal()
									handleSignClose()
								},
								label: 'Agora não'
							}
						})
					} else throw resp?.data?.message
				} else throw resp?.data?.message
			})
			.catch(e => {
				setLoading(false)
				handleShowModal({
					title: 'Pedimos desculpa',
					message: `Erro: ${e}`,
					buttonText: 'Ok, entendi'
				})
			})
	}

	const getPatientPrescriptions = async () => {
		setLoading(true)
		getPrescriptions(state.session.cpf, cpf)
			.then(res => {
				if (res && res.status === 200) {
					const prescriptions = []
					orderDate(res.data).forEach(item => {
						const docKeys = Object.keys(item.file)
						const signed = docKeys.filter(
							key => item.file[key] === true && item.file[key.replace('Assigned', '')] !== ''
						)
						const notSigned = docKeys.filter(
							key => item.file[key] === false && item.file[key.replace('Assigned', '')] !== ''
						)

						if (signed.length)
							prescriptions.push({
								...item,
								dateFormatted: isoToDateHour(item.date),
								id: `${item.id}-true`,
								docs: signed,
								signed: true
							})
						if (notSigned.length)
							prescriptions.push({
								...item,
								dateFormatted: isoToDateHour(item.date),
								id: `${item.id}-false`,
								docs: notSigned,
								signed: false
							})
					})
					setPrescriptionList(prescriptions)
					setFilteredPrescriptions(prescriptions)
				} else {
					setPrescriptionList([])
					setFilteredPrescriptions([])
				}
				setLoading(false)
			})
			.catch(e => {
				handleShowModal({
					title: 'Pedimos desculpa',
					message: `Error:${e}`,
					buttonText: 'Ok, entendi'
				})
			})
	}

	const handleAssignDoc = (prontmedConsultationId: number, patientProntmedId: string) => {
		const then = res => {
			if (res && res.status >= 200 && res.status <= 207) handleSignStart()
			else
				handleShowModal({
					title: 'Pedimos desculpa',
					message: 'Não foi possível salvar a prescrição para essa consulta',
					buttonText: 'Tentar de novo',
					onClose: posConsultation,
					secondButton: {
						onClick: () => {
							handleShowModal()
							handleSignClose()
						},
						label: 'Sair'
					}
				})
		}
		const posConsultation = () => {
			if (newConsultation)
				putProntmedId(consultationId, prontmedConsultationId, patientProntmedId).then(then)
			else
				postCreateClosedConsultationBradesco(
					consultationId,
					prontmedConsultationId,
					patientProntmedId
				).then(then)
			handleShowModal()
		}
		posConsultation()
	}

	const handleAcceptance = (param: boolean) => setDigitalCertificateAcceptance(param)

	const handleDigitalCertificateModal = (value?: boolean) => {
		setIsDigitalCertificate(!!value)
		setDigitalCertificateAcceptance(null)
	}

	const getPush = () => {
		setLoading(true)
		setShowMessage(false)
		getPushValid(cpfAssinatura || state.session.cpf).then(resPush => {
			if (resPush && resPush.status === 200)
				setDataToSign(prev => ({ ...prev, codeAuth: resPush.data }))
			else {
				handleShowModal({
					message: `Error: ${resPush?.data?.message}`,
					title: 'Erro ao enviar push',
					buttonText: 'Tentar de novo',
					onClose: getPush,
					secondButton: {
						onClick: () => {
							handleShowModal()
							handleSignClose()
						},
						label: 'Agora não'
					}
				})
			}
			setLoading(false)
		})
	}

	const checkPushReady = () => {
		const { codeAuth, certifier, accessTokens } = dataToSign
		setLoading(true)
		if (!accessTokens[certifier]) {
			postCheckPushValid(codeAuth, certifier.toUpperCase(), cpfAssinatura || state.session.cpf)
				.then(res => {
					setLoading(false)
					if (res && res.status === 200) {
						const { access_token } = res.data
						dispatch({
							type: TYPES.SET_USER_SESSION,
							payload: {
								accessTokens: {
									...(state.session.accessTokens || {}),
									[certifier]: access_token
								}
							}
						})
						setDataToSign(prev => ({
							...prev,
							accessTokens: { ...prev.accessTokens, [certifier]: access_token },
							stepLabel: 'SIGNATURE'
						}))
					} else throw new Error('')
				})
				.catch(() => {
					handleShowModal({
						buttonText: 'Tentar de novo',
						message:
							certifier === 'valid'
								? 'A certificadora não conseguiu identificar a autorização pelo App'
								: 'Aconteceu um erro no processo de assinatura',
						onClose: () => {
							setDataToSign(prev => ({
								...prev,
								stepLabel:
									certifier === 'valid'
										? dataToSign.stepLabel === 'CHECK_READY'
											? 'TRY_AGAIN_CHECK_READY'
											: 'CHECK_READY'
										: 'PIN_SOLUTI'
							}))
							handleShowModal()
						},
						title: 'Validação',
						secondButton: {
							onClick: () => {
								handleShowModal()
								handleSignClose()
							},
							label: 'Agora não'
						}
					})
					setLoading(false)
				})
		} else {
			setDataToSign(prev => ({
				...prev,
				stepLabel: 'SIGNATURE'
			}))
		}
	}

	const renderAreaActive = (area: string) => {
		switch (area) {
			case AREA_PATHS.EXAMS:
				const { searchText, startDate, endDate } = customState
				return (
					<ContainerResults>
						<SearchTextDate
							state={{
								endDate,
								startDate,
								searchText
							}}
							onChangeFilter={handleFilter('')}
							onKeyPress={handleFilteredExams}
							placeholderSearch='Pesquise pelo nome ou sigla do exame'
							labelDate='Busca exame por data'
							onFilter={handleFilteredExams}
						/>
						<Col size='12'>
							<Grid rowGap='0'>
								{Object.keys(examsByDay).map((key: string, index: number) => (
									<Col
										id={`col-collapse-group-${index}`}
										key={`key-collapse-group-${index}`}
										size='12'
									>
										<CollapseExam
											date={key}
											list={examsByDay[key]}
											onCollapse={handleClickCollapse(key)}
											active={key === currentActive}
											cpf={cpf}
											setLoading={setLoading}
										/>
									</Col>
								))}
							</Grid>
						</Col>
					</ContainerResults>
				)
			case AREA_PATHS.PRESCRIPTIONS:
				const { searchTextPrescription, startDatePrescription, endDatePrescription } = customState

				return (
					<ContainerResults>
						<SearchTextDate
							state={{
								endDate: endDatePrescription,
								startDate: startDatePrescription,
								searchText: searchTextPrescription
							}}
							onChangeFilter={handleFilter('Prescription')}
							onKeyPress={handleFilterPrescriptions}
							placeholderSearch='Pesquise por medicamento, anotações, etc'
							labelDate='Filtrar prescrição por data'
							onFilter={handleFilterPrescriptions}
							idSufix='prescription'
							onlyDate
						/>
						<Col size='12'>
							<Grid rowGap='0'>
								{filteredPrescriptions.length > 0 ? (
									filteredPrescriptions.map((item: any, index: number) => (
										<Col
											id={`col-collapse-group-${index}-prescriptions`}
											key={`key-collapse-group-${index}-prescriptions`}
											size='12'
										>
											<CollapsePrescription
												{...item}
												active={item.id === prescriptionActive}
												date={item.dateFormatted}
												onCollapse={handleCollapsePrescription(item.id)}
												signed={item.signed}
												docs={item.docs}
												onSendEmail={handleSendEmail(item.medicalEncounterId, item.id, item.signed)}
												onDownload={handleDownload(item.medicalEncounterId, item.id)}
												onSendSMS={handleSendSMS(item.medicalEncounterId, item.id)}
												onSign={handleSign(item.medicalEncounterId)}
											/>
										</Col>
									))
								) : (
									<Col overflow='initial'>
										<Span fontSize='20px/20px' bold padding='30px'>
											Nenhuma prescrição foi encontrada
										</Span>
									</Col>
								)}
							</Grid>
						</Col>
					</ContainerResults>
				)
			default:
				if (isMobile) setSessionOpened(AREA_PATHS.EXAMS)
				else
					return (
						<MedicalRecord
							onClosePront={handleAssignDoc}
							patientCPF={cpf}
							doctorEmail={doctorEmail}
							showModal={handleShowModal}
						/>
					)
		}
	}

	return (
		<>
			{openModalDigitalSign && <ModalDigitalSign {...paramsModalDigitalSign} />}
			{isDigitalCertificate && (
				<DigitalCertificateModal
					digitalCertificateAcceptance={digitalCertificateAcceptance}
					handleAcceptance={handleAcceptance}
					handleModal={handleDigitalCertificateModal}
				/>
			)}
			{loading && <Loader />}
			{showMessage && (
				<MessageModal {...messageToShow} onClose={messageToShow?.onClose || setShowMessage} />
			)}

			<TemplatePage>
				<MobileMenuConsulting active={sessionOpened} onChange={handleChangeView} />
				<ComponentWrapper>
					<Col className='left' size='3'>
						<Sidebar
							link={link}
							name={name}
							email={email}
							phone={phone}
							cpf={cpf}
							doctorCpf={cpf}
							birthDay={birthDay}
							sessionOpened={sessionOpened}
							haveExams={haveExams}
							onChangeView={handleChangeView}
							lastConsulting={lastConsulting}
						/>
					</Col>
					<Col className='right' size='9'>
						<Grid>
							<SpacedCol size='12' flex justifyContent='space-between'>
								<Grid>
									<Col sm='10px 0 0'>
										<ArrowIcon id='icon-arrow-back' onClick={backToHome} />
									</Col>
									<Col sm='10px 0 0' overflow='initial'>
										<Span id='span-type-view' bold fontSize='32px/40px' fontsm='20px'>
											{sessionOpened}
										</Span>
									</Col>
									<Col
										flex
										alingItems='center'
										className='breadcrumbs'
										sm='10px 0 0'
										overflow='initial'
									>
										<Span fontSize='12px/20px'>
											<BackToHome id='button-back-home' onClick={backToHome}>
												Página Inicial
											</BackToHome>{' '}
											/ <b>{sessionOpened}</b>
										</Span>
									</Col>
								</Grid>
							</SpacedCol>
							<MedicalRecordContainer size='12'>
								<Col size='12'>{renderAreaActive(sessionOpened)}</Col>
							</MedicalRecordContainer>
						</Grid>
					</Col>
				</ComponentWrapper>
			</TemplatePage>
		</>
	)
}

export default ExamResults
