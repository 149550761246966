import React, { useContext, useEffect, useState } from 'react'
import callGetProntmed from '../../services/get-prontmed'
import IModalMessage from '../../types/IModalMessage'
import IPropsIframe from '../../types/IPropsIFrame'
import { Iframe } from '../Iframe'
import { Loader } from '../Loader'

interface IProps {
	patientCPF: string
	doctorEmail: string
	onClosePront: () => void
	showModal: (args?: IModalMessage) => void
}
const MedicalRecord = ({ patientCPF, doctorEmail, onClosePront, showModal }: IProps) => {
	const [loading, setLoading] = useState(true)
	const [frameOptions, setFrameOptions] = useState<IPropsIframe>()
	const [messageToShow, setMessageToShow] = useState<IModalMessage>()

	useEffect(() => {
		if (messageToShow)
			showModal({
				onClose: messageToShow?.onClose || showModal(),
				title: messageToShow?.title || '',
				message: messageToShow?.message || '',
				buttonText: messageToShow?.buttonText || ''
			})
	}, [messageToShow])

	useEffect(() => {
		setLoading(true)
		if (patientCPF) getProntMed()
	}, [doctorEmail, patientCPF])

	const getProntMed = () => {
		callGetProntmed(patientCPF)
			.then(response => {
				if (response.status === 200) {
					setFrameOptions({
						origin: `${process.env.GATSBY_PRONT_MED_ORIGIN}`,
						clientId: response.data.clientId,
						clientSecret: response.data.secretKey,
						patientId: response.data.id.toString(),
						email: response.data.doctorEmail || doctorEmail,
						onLoad: () => setLoading(false),
						onClose: onClosePront
					})
				} else {
					setMessageToShow({
						message: `Ops. ${response.data.message}`
					})
				}
			})
			.catch(e => {
				setMessageToShow({
					message: `Ops. ${JSON.stringify(e)}`
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<>
			{loading && <Loader />}
			{frameOptions && <Iframe {...frameOptions} />}
		</>
	)
}

export default MedicalRecord
